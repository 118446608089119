import React from "react";
import { AuthRoutes, PublicRoutes } from "@pie/online-account-externals";
import { Redirect, RouteComponentProps } from "react-router-dom";
import microApps from "./micro-apps";
import MicroApp from "./micro-apps/MicroApp";
import {
  PaymentEmailSent,
  PaymentLinkExpired,
  PaymentSetup,
  ZuoraCallback
} from "./pages";
import { TerminalPage } from "@pages/TerminalPage/TerminalPage";
import {
  AuditIntake,
  CreatePassword,
  EmailSendSuccess,
  ErrorPage,
  ForgotPassword,
  ResetPassword,
  SecurityUpgrade,
  SendRegistrationEmail,
  SignIn
} from "./components";
import { ResetEmailSent } from "@components/Auth/ResetEmailSent";
import { ResetPasswordLinkExpired } from "@components/Auth/ResetPasswordLinkExpired";
import { RegistrationLinkExpired } from "@components/Auth/CreateAccount/RegistrationLinkExpired";
import { RegistrationLinkInvalid } from "@components/Auth/CreateAccount/RegistrationLinkInvalid";

export interface AppRoute {
  exact?: boolean;
  key?: string;
  path?: string;
  component?: React.ComponentType;
  render?: (props: RouteComponentProps) => React.ReactNode;
}

export const authenticatedRoutes = (microAppData: any): AppRoute[] => [
  {
    path: AuthRoutes.ERROR,
    render: () => <ErrorPage />
  },
  {
    path: AuthRoutes.AUDIT,
    component: AuditIntake
  },
  ...microApps(microAppData).map<AppRoute>(({ path, exactMatch, ...app }) => ({
    exact: exactMatch,
    path: path ?? app.name,
    render: () => <MicroApp {...app} />
  }))
];

export const publicRoutes: AppRoute[] = [
  {
    path: PublicRoutes.SIGN_IN,
    component: SignIn
  },
  {
    path: PublicRoutes.FORGOT_PASSWORD,
    component: ForgotPassword
  },
  {
    path: PublicRoutes.CREATE_ACCOUNT,
    component: SendRegistrationEmail
  },
  {
    path: PublicRoutes.CREATE_PASSWORD,
    component: CreatePassword,
    exact: true
  },
  {
    path: PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_EXPIRED,
    component: RegistrationLinkExpired
  },
  {
    path: PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_INVALID,
    component: RegistrationLinkInvalid
  },
  {
    path: PublicRoutes.EMAIL_SEND_SUCCESS,
    component: EmailSendSuccess
  },
  {
    path: PublicRoutes.SECURITY_UPGRADE,
    component: SecurityUpgrade
  },
  {
    path: PublicRoutes.RESET_PASSWORD,
    component: ResetPassword
  },
  {
    path: PublicRoutes.RESET_EMAIL_SENT,
    component: ResetEmailSent
  },
  {
    path: PublicRoutes.RESET_EMAIL_LINK_EXPIRED,
    component: ResetPasswordLinkExpired
  },
  {
    path: PublicRoutes.PAYMENT_SETUP_CUSTOMER,
    component: PaymentSetup
  },
  {
    path: PublicRoutes.PAYMENT_SETUP_PROSPECT,
    component: PaymentSetup
  },
  {
    path: PublicRoutes.PAYMENT_PROSPECT_SUCCESS,
    render: () => (
      <TerminalPage
        pageType="success"
        mainText="This policy has been set up for recurring payments."
        subText="You will not be charged until your policy is ready to be issued."
      />
    )
  },
  {
    path: PublicRoutes.PAYMENT_CUSTOMER_SUCCESS,
    render: () => (
      <TerminalPage
        pageType="success"
        mainText="Your payment method is updated."
        subText="Your next payment will be billed to this payment method."
      />
    )
  },
  {
    path: PublicRoutes.PAYMENT_TECH_ERROR,
    render: () => (
      <TerminalPage
        pageType="error"
        mainText="Sorry, we are unable to update your payment method due to a technical error."
        subText="Please contact us for help."
        callBoxText="Call now"
      />
    )
  },
  {
    path: PublicRoutes.POLICY_UNAVAILABLE_ERROR,
    render: () => (
      <TerminalPage
        pageType="error"
        header="Please try again later."
        mainText="This policy is currently unavailable. For help please contact us."
        callBoxText="Call now"
      />
    )
  },
  {
    path: PublicRoutes.GENERAL_ERROR,
    render: () => (
      <TerminalPage
        pageType="error"
        mainText="Sorry, we are experiencing a technical issue."
        subText="Please contact us for help."
        callBoxText="Call now"
      />
    )
  },
  {
    path: PublicRoutes.PAYMENT_LINK_EXPIRED,
    component: PaymentLinkExpired
  },
  {
    path: PublicRoutes.PAYMENT_EMAIL_SENT,
    component: PaymentEmailSent
  },
  {
    path: "/zuora-callback",
    component: ZuoraCallback
  }
];

export const publicRedirectRoute: AppRoute[] = [
  {
    key: "redirect",
    component: () => <Redirect to={PublicRoutes.SIGN_IN} />
  }
];

export const authenticatedRedirectRoute: AppRoute[] = [
  {
    key: "redirect",
    component: () => <Redirect to={AuthRoutes.HOME} />
  }
];

// @ts-ignore
export const PageNames: {
  [key in PublicRoutes | AuthRoutes]: string;
} = {
  [AuthRoutes.DOCUMENTS]: "Documents",
  [AuthRoutes.ERROR]: "Error",
  [AuthRoutes.HOME]: "Dashboard",
  [AuthRoutes.POLICY]: "Policy",
  [AuthRoutes.PAYMENTS]: "Payments",
  [PublicRoutes.CONTACT_US]: "Contact Us",
  [PublicRoutes.CREATE_ACCOUNT]: "Create Account",
  [PublicRoutes.CREATE_PASSWORD]: "Create Password",
  [PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_EXPIRED]:
    "Create Password Link Expired",
  [PublicRoutes.CREATE_PASSWORD_EMAIL_LINK_INVALID]:
    "Create Password Link Invalid",
  [PublicRoutes.EMAIL_SEND_SUCCESS]: "Registration Email Sent",
  [PublicRoutes.FORGOT_PASSWORD]: "Forgot Password",
  [PublicRoutes.PAYMENT_CUSTOMER_SUCCESS]: "Customer Payment Success",
  [PublicRoutes.PAYMENT_EMAIL_SENT]: "Payment Email Sent",
  [PublicRoutes.PAYMENT_LINK_EXPIRED]: "Payment Link Expired",
  [PublicRoutes.PAYMENT_PROSPECT_SUCCESS]: "Prospect Payment Success",
  [PublicRoutes.PAYMENT_SETUP_CUSTOMER]: "Customer Payment Setup",
  [PublicRoutes.PAYMENT_SETUP_PROSPECT]: "Prospect Payment Setup",
  [PublicRoutes.PAYMENT_TECH_ERROR]: "Payment Technical Error",
  [PublicRoutes.RESET_EMAIL_SENT]: "Reset Password Email Sent",
  [PublicRoutes.RESET_PASSWORD]: "Reset Password",
  [PublicRoutes.RESET_EMAIL_LINK_EXPIRED]: "Reset Password Link Expired",
  [PublicRoutes.SECURITY_UPGRADE]: "Security Upgrade",
  [PublicRoutes.SIGN_IN]: "Sign In",
  [PublicRoutes.POLICY_UNAVAILABLE_ERROR]: "Policy Unavailable",
  [PublicRoutes.GENERAL_ERROR]: "General Error"
};
