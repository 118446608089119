import React, { ChangeEvent, useEffect } from "react";
import {
  Loader,
  PaymentAcknowledgement,
  RadioButtons,
  SideModal
} from "@pie/components";
import { useDispatch } from "react-redux";
import { useZuoraPaymentDrawerState, zuoraActions } from "@stores/zuora";
import useZuora from "@components/ZuoraPaymentDrawer/useZuora";
import {
  Box as MuiBox,
  BoxProps,
  Button,
  Grid,
  Typography
} from "@material-ui/core";
import { RadioListOption } from "@pie/components/dist/atoms/RadioButtons/RadioButton";
import {
  PaymentMethod,
  ZuoraSubmitResponse
} from "@components/ZuoraPaymentDrawer/types";
import { getErrorFromZuoraResponse } from "@utils/helpers";

const Box = MuiBox as (props: BoxProps) => JSX.Element;
const paymentMethodOptions: RadioListOption[] = [
  {
    label: "Credit or debit card",
    value: "CreditCard"
  },
  {
    label: "Bank account",
    value: "ACH"
  }
];

const PaymentDrawer = () => {
  const dispatch = useDispatch();
  const { loading, open, paymentType } = useZuoraPaymentDrawerState();

  const { render: renderPaymentPage, submit: submitPaymentPage } = useZuora({
    onLoad: () => dispatch(zuoraActions.paymentPageLoaded())
  });

  const handleModalClose = () => {
    dispatch(zuoraActions.closePaymentDrawer());
  };

  const handlePaymentMethodChange = (
    event: ChangeEvent<HTMLInputElement>,
    value: string
  ): void => {
    const paymentType = value as PaymentMethod;
    dispatch(zuoraActions.paymentTypeUpdated({ paymentType }));
    renderPaymentPage(paymentType);
  };

  const handleSubmitButtonClicked = () => {
    const zuoraIframe = document.querySelector<HTMLIFrameElement>(
      "#zuora_payment iframe"
    );
    if (zuoraIframe) {
      zuoraIframe.style.display = "none";
    }
    dispatch(zuoraActions.paymentPageSubmitted());
    submitPaymentPage();
  };

  useEffect(() => {
    // This global callback is invoked indirectly by Zuora when payment page is submitted.
    // See the ZuoraCallback component for more info
    // @ts-ignore
    window.zuoraCallback = (response: ZuoraSubmitResponse) => {
      if (response.success?.toLowerCase() === "true") {
        dispatch(zuoraActions.paymentUpdateSuccess());
      } else {
        dispatch(
          zuoraActions.paymentUpdateError(getErrorFromZuoraResponse(response))
        );
      }
    };
    return () => {
      // @ts-ignore
      delete window.zuoraCallback;
    };
  }, [dispatch]);

  return (
    <SideModal
      title="Update payment method"
      open={open}
      onClose={handleModalClose}
    >
      <div>
        <Grid container>
          <Grid sm={12} item>
            <RadioButtons
              label={
                <Typography gutterBottom>Select payment method</Typography>
              }
              labelOnTop
              options={paymentMethodOptions}
              onChange={handlePaymentMethodChange}
              value={paymentType}
            />
          </Grid>
        </Grid>
        {loading && <Loader />}
        <div id="zuora_payment" />
        <PaymentAcknowledgement />
        <Grid container justify="flex-end" alignItems="center">
          <Box clone order={{ xs: 2, sm: 1 }}>
            <Button onClick={handleModalClose}>Cancel</Button>
          </Box>
          <Box clone order={{ xs: 1, sm: 2 }}>
            <Button
              onClick={handleSubmitButtonClicked}
              color="primary"
              type="submit"
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </div>
    </SideModal>
  );
};

export default PaymentDrawer;
