import React, { useEffect } from "react";

export const ZuoraCallback = () => {
  useEffect(() => {
    const params = Object.fromEntries(
      new URLSearchParams(window.location.search)
    );
    // This is needed due to complexities in updating micro-frontend state while the WrapperUI state is being updated.
    // In short, `setTimeout` makes sure the callback is invoked "in the background" and not during a WrapperUI render.
    setTimeout(() => {
      // @ts-ignore
      window.parent.window?.zuoraCallback(params);
    });
  }, []);
  return <p>payment method updated.</p>;
};
