import { ApiRoute, fetchOptions } from "@api/constants";

type ZuoraPaymentSetupHamResponse = {
  accountId: string;
  key: string;
  signature: string;
  tenantId: string;
  token: string;
};
// HAM returns the accountId as `accountId` but zuora client params requires it as `field_accountId`
type ZuoraPaymentSetupResponse = Omit<
  ZuoraPaymentSetupHamResponse,
  "accountId"
> & { field_accountId: string };

export type ZuoraPaymentSetupParams = {
  idToken: string;
  paymentMethod: string;
  policyNumber: string;
  // Zuora needs lowercase, HAM needs initial caps
  style?: "Inline" | "Overlay";
  submitEnabled?: boolean;
};

const isZuoraPaymentSetupHamResponse = (
  object: any
): object is ZuoraPaymentSetupHamResponse =>
  object?.key &&
  object?.signature &&
  object?.tenantId &&
  object?.token &&
  object?.accountId;

export const zuoraPaymentSetup = async (
  params: ZuoraPaymentSetupParams
): Promise<ZuoraPaymentSetupResponse | null> => {
  const {
    idToken,
    paymentMethod,
    policyNumber,
    style = "Inline",
    submitEnabled = false
  } = params;

  const requestInit: RequestInit = {
    ...fetchOptions,
    method: "POST",
    headers: {
      ...fetchOptions.headers,
      Authorization: `Bearer ${idToken}`
    }
  };
  const urlParams = new URLSearchParams({
    paymentMethod,
    style,
    submitEnabled: submitEnabled.toString()
  });
  const url = `${ApiRoute.BILLINGV2}/zuora-payment-setup/${encodeURIComponent(
    policyNumber
  )}?${urlParams.toString()}`;
  const response = await fetch(url, requestInit);

  if (!response.ok) return null;
  try {
    const responseBody = await response.json();
    if (isZuoraPaymentSetupHamResponse(responseBody)) {
      const { accountId: field_accountId, ...rest } = responseBody;
      return { field_accountId, ...rest };
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};
