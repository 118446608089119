export enum ApiRoute {
  ANALYTICS = "/action",
  BILLINGV2 = "/api/v2/billing",
  DOCUMENT_REQUEST = "/api/v1/document-requests",
  PAYMENT_SETUP_BASE_ROUTE = "/api/v1/payment-setup",
  CARD_PAYMENT_SETUP = "/api/v1/payment-setup/card",
  BANK_PAYMENT_SETUP = "/api/v1/payment-setup/bank",
  BASE_USER_ROUTE = "/api/v1/users",
  TERMS_AND_CONDITIONS_BASE = "/api/v1/terms-and-conditions",
  INTERNAL_PAYMENT_SETUP_BASE = "/api/v1/internal-payment-setup",
  GET_POLICIES = "/api/v1/policies",
  HEALTH_CHECK = "/api/v1/ham/readyz",
  AUDIT_INTAKE_BASE_ROUTE = "/api/v1/audit-intake"
}

export enum ApiPath {
  SEND_REGISTRATION_EMAIL = "/email/registration",
  RESEND_PAYMENT_EMAIL = "/resend-email",
  ACH_PATH = "/bank",
  CARD_PATH = "/card",
  CUSTOM_COI = "/coi",
  INSTANT_COI = "/instant-coi"
}

export const fetchOptions: RequestInit = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
};
