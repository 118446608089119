import { useCallback, useEffect, useState } from "react";
import {
  PaymentMethod,
  ZuoraCardBINInfo,
  ZuoraClient,
  ZuoraClientParams,
  ZuoraOnLoadCallback,
  ZuoraOnSubmit
} from "./types";
import {
  zuoraPaymentSetup,
  ZuoraPaymentSetupParams
} from "@api/zuoraPaymentSetupApi";
import { useSelector } from "react-redux";
import { selectAuthUserIdToken } from "@pie/online-account-externals";
import { useSettings } from "@pie/components";
import noop from "@utils/noop";
import { useZuoraPaymentDrawerState } from "@stores/zuora";

// TODO: implement error state
// This is called if zuora.render doesn't succeed
const errHandler = (...args: unknown[]) => {
  console.log("z error", args);
};

type ZuoraAppSettings = {
  zuora: {
    achPageId: string;
    creditCardPageId: string;
    iframeUrl: string;
  };
};

const getZuoraClientParameters = async (
  {
    idToken,
    paymentMethod,
    policyNumber,
    style = "Inline",
    submitEnabled = false
  }: ZuoraPaymentSetupParams,
  { achPageId, creditCardPageId, iframeUrl }: ZuoraAppSettings["zuora"]
): Promise<ZuoraClientParams> => {
  if (!idToken) throw new Error("You have been signed out.");
  const response = await zuoraPaymentSetup({
    idToken,
    paymentMethod,
    policyNumber,
    style,
    submitEnabled
  });
  if (!response) throw new Error("Payment setup failed.");
  const id = paymentMethod === "ACH" ? achPageId : creditCardPageId;
  return {
    ...response,
    field_currency: "USD",
    id,
    style: "inline" as const,
    submitEnabled: "false" as const,
    url: iframeUrl
  };
};

const useZuoraClient = () => {
  const [zuoraClient, setZuoraClient] = useState<ZuoraClient | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js";
    script.async = true;
    script.addEventListener("load", () => {
      // @ts-ignore
      setZuoraClient(window.Z ?? null);
    });
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [setZuoraClient]);

  return zuoraClient;
};

/**
 * @typedef {Object} UseZuoraReturn
 * @property render - Call this with the payment method to render the corresponding payment page
 * @property submit - Call this to submit the payment page
 */
type UseZuoraReturn = {
  render: (paymentMethod: PaymentMethod) => Promise<boolean>;
  submit: () => void;
};
/**
 * @typedef {Object} UseZuoraParams
 */
type UseZuoraParams = {
  onCardInfo?: ZuoraCardBINInfo;
  onLoad?: ZuoraOnLoadCallback;
  onSubmit?: ZuoraOnSubmit;
};

/**
 * Returns a `render` function which can be used to render a payment page, and a `submit` function which can be used
 * to submit the payment page.  `submit` is only needed if submitEnabled is false in the zuora client parameters.
 * @param {UseZuoraParams} eventHandlers object containing the optional event handlers
 * @returns {UseZuoraReturn} An object containing the `render` and `submit` functions
 */
const useZuora = ({
  onCardInfo,
  onLoad,
  onSubmit
}: UseZuoraParams): UseZuoraReturn => {
  const zuoraClient = useZuoraClient();
  const appSettings = useSettings<ZuoraAppSettings>();
  const idToken = useSelector(selectAuthUserIdToken);
  const { policyNumber } = useZuoraPaymentDrawerState();

  useEffect(() => {
    if (!zuoraClient) return;
    if (onCardInfo) zuoraClient.setEventHandler("cardBinInfo", onCardInfo);
    if (onLoad) zuoraClient.setEventHandler("onloadCallback", onLoad);
    if (onSubmit) zuoraClient.setEventHandler("onSubmit", onSubmit);
  }, [onCardInfo, onLoad, onSubmit, zuoraClient]);

  const render = useCallback(
    async (paymentMethod: PaymentMethod): Promise<boolean> => {
      if (!idToken || !zuoraClient) return false;
      try {
        const clientParams = await getZuoraClientParameters(
          {
            idToken,
            paymentMethod,
            policyNumber: policyNumber as string
          },
          appSettings.zuora
        );
        zuoraClient.render(clientParams, {}, errHandler);
      } catch (err) {
        console.error(err);
        return false;
      }
      return true;
    },
    [appSettings.zuora, idToken, policyNumber, zuoraClient]
  );

  const submit = zuoraClient?.submit ?? noop;

  return { render, submit };
};

export default useZuora;
